import "./Likeme.css";

const LikeMe = () => {
  return (
    <div className="likeme-wrap">
      <p>[Like-btn]</p>
      <p>[Likes: ]</p>
    </div>
  );
};

export default LikeMe;
