import { UnderConstruction } from "../Partials/UnderConstruction/UnderConstruction";
import Footer from "../Partials/Footer/Footer";
import { Header } from "../Partials/Header/Header";

const Dream = () => {
  return (
    <>
      <Header pageTitle="dream" />
      <UnderConstruction />
      <Footer />
    </>
  );
};

export default Dream;
