import Footer from "../Partials/Footer/Footer";
import { Header } from "../Partials/Header/Header";
import { UnderConstruction } from "../Partials/UnderConstruction/UnderConstruction";

const Play = () => {
  return (
    <>
      <Header pageTitle="play" />
      <UnderConstruction />
      <Footer />
    </>
  );
};

export default Play;
